import React, { useState ,useEffect} from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import DirectionToggle from './components/DirectionToggle';
import InfoContext from "./global/InfoContext"
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Socket from './global/socket'
const history = createBrowserHistory();
const store = configureStore();

function App() {
  const [statusF, setStatusF]=useState({data:null,allFacturas:null,id:0})
  const [sesion, setSesion]=useState(null)
  const [jwt,setJWT]=useState(null)
  const [status,setStatus]=useState(false)
  const [loading, setLoading]=useState(false)
  const [user, setUser]=useState(null)
  const [socket, setSocket]=useState(null)
  const toastRef = (val) => toast(val);

  useEffect(() => {
    if(jwt==null){
      console.log("levantando jwt socket...........")
      
    }
    else if(jwt!=null && user!=null){
      //Socket.subscribe("ssag/"+user.topic+"/+");
      Socket.subscribe("ssag/"+user.topic+"/+");
      Socket.on('message', function (topic, message) {
        const res = topic.split("/");
        if(res.length==3){
          if (true){
            try {
              const note = JSON.parse(message.toString());
              if(note.t!=undefined || note.q!=undefined)
              setSocket({input:note.i,output:note.o,time:note.t,quality:note.q,topic:res[2]})
            }catch (e) {
              //alert("fallita")
              console.log(message.toString())
            }
            
          }
        }
      });
    }
    
    else {
      
      console.log("terminando socket...........")
    }
    
  }, [jwt]);

  useEffect(() => {
    const jwt =localStorage.getItem("JWT")
    const id =localStorage.getItem("ID")
    const tipo =localStorage.getItem("TIPO")
    const topic =localStorage.getItem("TOPIC")
    if(jwt!=null){
      setUser({
        id,topic
      })
      setJWT(jwt)
      setSesion(tipo)
      //SetloginState(true)
    }
    else setSesion(tipo)
    setStatus(true)
  }, [])
  if(status == false ) return null
  return (
      <StoreProvider store={store}>
        <InfoContext.Provider value={{
          statusF,toastRef, setStatusF,setLoading,
          jwt,setJWT,setSesion,socket,user, setUser,sesion
          }}>
          <ThemeProvider theme={theme}>
            <StylesProvider direction='ltr'>
              <MuiPickersUtilsProvider utils={MomentUtils}>
              <LoadingOverlay active={loading} spinner={<BounceLoader />}>
                <Router history={history}>
                  <ScrollReset />
                  {jwt==null?renderRoutes(routes[1]):renderRoutes(routes[0])}
                </Router>
                </LoadingOverlay>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </InfoContext.Provider>
        <ToastContainer />
      </StoreProvider>
    
  );
}

export default App;
