/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes=[
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/devices" />
    },
    {
      path: '/auth',
      component: AuthLayout,
      routes: [
        {
          component: () => <Redirect to="/devices" />
        }
      ]
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/users',
          exact: true,
          component: lazy(() => import('src/views/Users'))
        },
        {
          path: '/devices-list',
          exact: true,//component: 
          component:lazy(() => import('src/views/DevicesList'))
        },
        {
          path: '/add-user',
          exact: true,//component: 
          component:lazy(() => import('src/views/AddUser'))
        },
        {
          path: '/notifications',
          exact: true,//component: 
          component:lazy(() => import('src/views/Notifications'))
        },
        {
          path: '/edit-user/:userid',
          exact: true,
          component: lazy(() => import('src/views/EditUser'))
        },
        {
          path: '/edit-device/:deviceid',
          exact: true,
          component: lazy(() => import('src/views/EditDevice'))
        },
        {
          path: '/edit-devices/:userid',
          exact: true,
          component: lazy(() => import('src/views/EditDevicesUsers'))
        },
        {
          path: '/device/:deviceid',
          exact: true,//component: 
          component:lazy(() => import('src/views/Device'))
        },
        {
          path: '/devices',
          exact: true,//component: 
          component:lazy(() => import('src/views/Devices'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      component: AuthLayout,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          component: lazy(() => import('src/views/Login'))
        },
        {
          component: () => <Redirect to="/auth/login" />
        }
      ]
    }
  ]
]
export default routes
